<template>
  <div class="picking-warehouse-container">
    <my-nav-bar
      title="仓库拣货"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-cell
        v-for="(warehouse, index) in warehouses"
        :key="index"
        center
        :title="warehouse.name"
        :value="warehouse.order_goodses_count > 0 ? warehouse.order_goodses_count + '种商品待拣货' : ''"
        is-link
        @click="toPickingListView(warehouse)"
      />
    </div>
  </div>
</template>

<script>
import warehouseApi from '@/api/warehouse'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'PickingWarehouse',
  components: { myNavBar },
  data() {
    return {
      warehouses: null
    }
  },
  created() {
    this.getWarehouses()
  },
  methods: {
    getWarehouses() {
      warehouseApi.picking().then(res => {
        this.warehouses = res.data
      })
    },
    toPickingListView(warehouse) {
      this.$router.push({ path: 'picking', query: { warehouse_name: warehouse.name, warehouse_id: warehouse.id }})
    }
  }
}
</script>

<style lang="scss" scoped>
  .picking-warehouse-container {
    &__button {
      margin-left: 15px;
    }
  }
</style>
