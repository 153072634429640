var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "picking-warehouse-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "仓库拣货",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, _vm._l(_vm.warehouses, function (warehouse, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "center": "",
        "title": warehouse.name,
        "value": warehouse.order_goodses_count > 0 ? warehouse.order_goodses_count + '种商品待拣货' : '',
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toPickingListView(warehouse);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }